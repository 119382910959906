import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './Play.css'
import headphone from './assets/images/headphone.png'
import power from './assets/images/power.png'

import prize from './assets/images/prize.png'

const BASE_URL = 'https://about-me-api.vercel.app';

const PlayComponent = () => {
    const [participants, setParticipants] = useState([]);
    const [currentId, setCurrentId] = useState('');
    const [prizeName, setPrizeName] = useState('');
    const [isTranslate, setIsTranslate] = useState(false);
    const [winnerName, setWinnerName] = useState('');
    const [winnerExist, setWinnerExist] = useState(false);
    const [countdownValue, setCountdownValue] = useState(10);
    const [previousWinner, setPreviousWinner] = useState('');
    const [previousId, setPreviousId] = useState();
    const [phoneNumber, setPhoneNumber] = useState('');

    const updateListIntervalRef = useRef();
    const updateTranslateIntervalRef = useRef();
    const updatePrizeAtStakeIntervalRef = useRef();

    const sendSMS = async () => {
        const url = 'https://allsms.zen-apps.com/api/sendsms.php';

        const postData = {
            content: `Y'ello ${winnerName}. tu as gagné 1 ${prizeName}. RDV au Stand MTM pour le récupérer.`,
            destinationPhone: phoneNumber,
            senderID: 'MTN PROMOTE'
        };

        console.log(postData);

        const axiosConfig = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        };

        try {
            const response = await axios.post(url, postData, axiosConfig);
            console.log(response.data); 
        } catch (error) {
            console.error('Une erreur s\'est produite lors de l\'envoi du message.', error);
        }
    };
    
    useEffect(() => {
        translate();

        return () => {
            clearInterval(updateListIntervalRef.current);
            clearInterval(updateTranslateIntervalRef.current);
            clearInterval(updatePrizeAtStakeIntervalRef.current);
        };
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            getAllParticipants();
        
        }, 4000);

        return () => clearInterval(interval);
    }, [previousWinner]);

    useEffect(() => {
        const interval = setInterval(() => {
            getPrizeAtStake()
        }, 3000);

        return () => clearInterval(interval);
    }, [previousWinner]);

    useEffect(() => {
        setWinnerExist(false);
    }, [previousId]);

    

    const startCountdown = () => {
        setWinnerExist(true);
        let countdown = 10;
        const interval = setInterval(() => {
            countdown -= 1;
            setCountdownValue(countdown);
            if (countdown === 0) {
                clearInterval(interval);
                console.log(countdown);
                sendSMS();
            }
        }, 1000);
    };



    const translate = () => {
        updateTranslateIntervalRef.current = setInterval(() => {
            setIsTranslate((prev) => !prev);
        }, 3000);
    };

    const getAllParticipants = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/participant`);
            if(response.data.length === 0){
                window.location.reload();
            }
            setParticipants(response.data);
            response.data.forEach((participant) => {
                console.log(participant);
                if (participant.statut === true) {
                    console.log(participant.User.nom);
                    setPhoneNumber(participant.User.telephone)
                    if (participant.User.nom !== previousWinner) {
                        startCountdown();
                        setPreviousWinner(participant.User.nom);
                        setWinnerName(participant.User.nom);
                        setPhoneNumber(participant.User.telephone)
                    }
                    setWinnerExist(true);
                }
            });
        } catch (error) {
            console.error('Erreur lors de la récupération des participants:', error);
        }
    };
   
    const getPrizeAtStake = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/prix/display`);
            setPreviousId(response.data.id)
            setCurrentId(response.data.name);
            setPrizeName(prizeName);
        } catch (error) {
            console.error('An error occurred while trying to retrieve the prize at stake', error);
        }
    };

    const getImagePath = (prizeName) => {
        
        switch (prizeName) {
            case 'headphone':
                return headphone;
            case 'power bank':
                return power;
            default:
                return power;
        }
    };
   
    return (
        <>
        <div className='bg-white'>
                <h1 className="fixed uppercase z-30 text-black font-bold px-32 shadow-md bg-white text-4xl" style={{ width: '28.6%', height: '50px' }}>
                    Participants
                </h1>
        </div>
            
        <div className="md:block">
            <div className="flex h-screen w-full z-50 bg-white overflow-hidden font-MTNBrighterSansBold">
                <div className="block h-full w-[40%]">
                   
                    <hr />
                    <div className="scroll-participants -z-50">
                        {participants.map((participant) => (
                            <p key={participant.User.id} className="name bg-black text-[#fc0] h-12 uppercase text-center text-2xl -z-20 shadow-xl">
                                {participant.User.nom}
                            </p>
                        ))}
                    </div>
                </div>
                <div className="spin w-full bg-[#fc0]">
                    <p
                        className={`text-center text-6xl mt-12 animate-bounce ${!isTranslate && !winnerExist ? '' : 'hidden'
                            }`}
                    >
                        Qui sera le gagnant<span id="interro" className="text-8xl">?</span>
                    </p>
                    <p
                        className={`text-center text-6xl mt-12 animate-bounce ${isTranslate && !winnerExist ? '' : 'hidden'
                            }`}
                    >
                        Who will be the winner<span id="interro" className="text-8xl">?</span>
                    </p>
                    <p
                        className={`text-center text-8xl mt-12 animate-bounce ${winnerExist && countdownValue === 0 ? '' : 'hidden'
                            }`}
                    >
                        Le gagnant est
                    </p>
                    <p
                        className={`text-center uppercase text-6xl mt-12 ${winnerExist && countdownValue === 0 ? '' : 'hidden'
                            }`}
                    >
                        {winnerName}
                    </p>
                    <div className="text-center w-full">
                        <img src={getImagePath(prizeName)}
                            className="-translate-y-10 translate-x-[200%] w-[20%] mt-16"
                            alt="lot"
                        />
                        <p id="pricename" className="text-5xl font-bold ml-6 -mt-8">
                            Cadeau surprise
                        </p>
                    </div>
                    <div className={`translate-y-[8vh] ${winnerExist ? '' : 'hidden'}`}>
                        <p
                            className={`text-center text-6xl mt-12 animate-pulse ${countdownValue !== 0 ? '' : 'hidden'
                                }`}
                        >
                            Tirage en cours...
                        </p>
                        <h2
                            className={`text-8xl text-center text-black animate-ping pt-5 ${countdownValue !== 0 ? '' : 'hidden'
                                }`}
                        >
                            {countdownValue}
                        </h2>
                    </div>
                    <div
                        className={`translate-y-[16vh] translate-x-[67vh] ${!winnerExist ? '' : 'hidden'
                            }`}
                    >
                        <div className="three-body">
                            <div className="three-body__dot"></div>
                            <div className="three-body__dot"></div>
                            <div className="three-body__dot"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
};

export default PlayComponent;